<div class="container">
<section class="padding-bottom-sm">

  <header class="section-heading heading-line">
    <h4 class="title-section text-uppercase">Catalog of Equipments</h4>
  </header>

  <div class="row row-sm">
    <div class="col-xl-2 col-lg-3 col-md-4 col-6">
      <div class="card card-sm card-product-grid">
        <a href="/contact" class="img-wrap"> <img src="assets/images/items/0.jpg"> </a>

        <figcaption class="info-wrap">
          <a href="/contact" class="title">Analytik Jena - Element analyzer series</a>
          <button type="submit" class="btn btn-block btn-warning" href="/contact" routerLink = "/contact">Get an Offer </button>
        </figcaption>
      </div>
    </div> <!-- col.// -->

    <div class="col-xl-2 col-lg-3 col-md-4 col-6">
      <div class="card card-sm card-product-grid">
        <a href="/contact" class="img-wrap"> <img src="assets/images/items/1.jpg"> </a>

        <figcaption class="info-wrap">
          <a href="/contact" class="title">Analytik Jena UV-VIS spectrophotometer</a>
          <button type="submit" class="btn btn-block btn-warning" href="/contact" routerLink = "/contact">Get an Offer </button>
        </figcaption>
      </div>
    </div> <!-- col.// -->

    <div class="col-xl-2 col-lg-3 col-md-4 col-6">
      <div class="card card-sm card-product-grid">
        <a href="/contact" class="img-wrap"> <img src="assets/images/items/3.jpg"> </a>

        <figcaption class="info-wrap">
          <a href="/contact" class="title">Analytik Jena - Atomic Absorption spectrometer series</a>
          <button type="submit" class="btn btn-block btn-warning" href="/contact" routerLink = "/contact">Get an Offer </button>
        </figcaption>

      </div>
    </div> <!-- col.// -->

    <div class="col-xl-2 col-lg-3 col-md-4 col-6">
      <div class="card card-sm card-product-grid">
        <a href="/contact" class="img-wrap"> <img src="assets/images/items/2.jpg"> </a>

        <figcaption class="info-wrap">
          <a href="/contact" class="title">Analytik Jena - ICP spectrometer series</a>
          <br>
          <button type="submit" class="btn btn-block btn-warning" href="/contact" routerLink = "/contact">Get an Offer </button>
        </figcaption>
      </div>
    </div> <!-- col.// -->

    <div class="col-xl-2 col-lg-3 col-md-4 col-6">
      <div class="card card-sm card-product-grid">
        <a href="/contact" class="img-wrap"> <img src="assets/images/items/5.jpg"> </a>

        <figcaption class="info-wrap">
          <a href="/contact" class="title">Gas Analyzers</a>
          <br>
          <br>
          <button type="submit" class="btn btn-block btn-warning" href="/contact" routerLink = "/contact">Get an Offer </button>
        </figcaption>
      </div>
    </div> <!-- col.// -->

    <div class="col-xl-2 col-lg-3 col-md-4 col-6">
      <div class="card card-sm card-product-grid">
        <a href="/contact" class="img-wrap"> <img src="assets/images/items/6.jpg"> </a>

        <figcaption class="info-wrap">
          <a href="/contact" class="title">Compound Sterilizers</a>
          <br>
          <button type="submit" class="btn btn-block btn-warning" href="/contact" routerLink = "/contact">Get an Offer </button>
        </figcaption>
      </div>
    </div> <!-- col.// -->

    <div class="col-xl-2 col-lg-3 col-md-4 col-6">
      <div class="card card-sm card-product-grid">
        <a href="/contact" class="img-wrap"> <img src="assets/images/items/12.jpg"> </a>
        <figcaption class="info-wrap">
          <a href="/contact" class="title">Centrafuges</a>
          <button type="submit" class="btn btn-block btn-warning" href="/contact" routerLink = "/contact">Get an Offer </button>
        </figcaption>
      </div>
    </div> <!-- col.// -->

    <div class="col-xl-2 col-lg-3 col-md-4 col-6">
      <div class="card card-sm card-product-grid">
        <a href="/contact" class="img-wrap"> <img src="assets/images/items/7.jpg"> </a>

        <figcaption class="info-wrap">
          <a href="/contact" class="title">Laboratory Balances</a>
          <button type="submit" class="btn btn-block btn-warning" href="/contact" routerLink = "/contact">Get an Offer </button>
        </figcaption>
      </div>
    </div> <!-- col.// -->

    <div class="col-xl-2 col-lg-3 col-md-4 col-6">
      <div class="card card-sm card-product-grid">
        <a href="/contact" class="img-wrap"> <img src="assets/images/items/8.jpg"> </a>
        <figcaption class="info-wrap">
          <a href="/contact" class="title">Reagents</a>
          <button type="submit" class="btn btn-block btn-warning" href="/contact" routerLink = "/contact">Get an Offer </button>
        </figcaption>
      </div>
    </div> <!-- col.// -->

    <div class="col-xl-2 col-lg-3 col-md-4 col-6">
      <div class="card card-sm card-product-grid">
        <a href="/contact" class="img-wrap"> <img src="assets/images/items/9.jpg"> </a>

        <figcaption class="info-wrap">
          <a href="/contact" class="title">Ph Meters</a>
          <button type="submit" class="btn btn-block btn-warning" href="/contact" routerLink = "/contact">Get an Offer </button>
        </figcaption>
      </div>
    </div> <!-- col.// -->

    <div class="col-xl-2 col-lg-3 col-md-4 col-6">
      <div class="card card-sm card-product-grid">
        <a href="/contact" class="img-wrap"> <img src="assets/images/items/11.jpg"> </a>

        <figcaption class="info-wrap">
          <a href="/contact" class="title">Distillators</a>
          <button type="submit" class="btn btn-block btn-warning" href="/contact" routerLink = "/contact">Get an Offer </button>
        </figcaption>
      </div>
    </div> <!-- col.// -->

    <div class="col-xl-2 col-lg-3 col-md-4 col-6">
      <div class="card card-sm card-product-grid">
        <a href="/contact" class="img-wrap"> <img src="assets/images/items/10.jpg"> </a>

        <figcaption class="info-wrap">
          <a href="/contact" class="title">Germicidal Lamps</a>
          <button type="submit" class="btn btn-block btn-warning" href="/contact" routerLink = "/contact">Get an Offer </button>
        </figcaption>
      </div>
    </div> <!-- col.// -->



  </div> <!-- row.// -->
</section>
</div>
