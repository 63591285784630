import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-category-banner',
  templateUrl: './home-category-banner.component.html',
  styleUrls: ['./home-category-banner.component.less']
})
export class HomeCategoryBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
