<header class="section-header">
  <section class="header-main border-bottom">
    <div class="container">

      <div class="row align-items-center">
        <div class="col-xl-2 col-lg-3 col-md-12">
          <img src = "assets/images/logos/analitik-logo.png" style="width: 120px; height: auto;" alt="Analitik Logo">
        </div>
      </div>
    </div>
  </section>
  <app-nav-bar></app-nav-bar>
  <router-outlet></router-outlet>
</header>

<div *ngIf="isHomeRoute()">
  <!-- Your HTML content to be rendered only on '/' route -->
<div class="container">

  <!-- Section main -->
  <section class="section-main bg padding-y">
    <main class="card">
      <div class="card-body">
        <div class="row">
          <aside class="col-lg col-md-y3 flex-lg-grow-0">
            <app-menu-category></app-menu-category>
          </aside> <!-- col.// -->
          <div class="col-md-9 col-xl-9 col-lg-9">
            <app-carousel-slider style="width: 100%; height: 100vh;"></app-carousel-slider>
          </div> <!-- col.// -->
        </div> <!-- row.// -->
      </div> <!-- card-body.// -->
    </main> <!-- card.// -->
  </section>

  <!-- Section deal -->

  <app-recommended-items></app-recommended-items>


</div> <!-- container end.// -->
</div>

<app-subscribe-form></app-subscribe-form>

<!-- <app-download-demo></app-download-demo> -->

<app-footer></app-footer>
