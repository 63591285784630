<div class="container">

  <!-- Section main -->
  <section class="section-main bg padding-y">
    <main class="card">
      <div class="card-body">
        <div class="row">
          <aside class="col-lg col-md-y3 flex-lg-grow-0">
            <!--insert contact info here-->
          <b>Phone:</b> +994 510 57 84
          <b>Mail:</b> analitikbaku@gmail.com
          <b>Address:</b> Baktiyar Vahabzada 20A, Baku, Azerbaijan

          </aside> <!-- col.// -->
          <div class="col-md-9 col-xl-9 col-lg-9">
            <a href="/contact" class="img-wrap"> <img src="assets/images/map-loc.jpg" style="width: 480px; height: auto"> </a>
            <img>
          </div> <!-- col.// -->
        </div> <!-- row.// -->
      </div> <!-- card-body.// -->
    </main> <!-- card.// -->
  </section>

  <!-- Section deal -->

</div> <!-- container end.// -->
