<div class="container">
<section class="padding-bottom-sm">
 <br>
 <br>
  <header class="section-heading heading-line">
    <h4 class="title-section text-uppercase">Catalog of Products</h4>
  </header>

  <div class="row row-sm">
    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
      <div class="card card-sm card-product-grid">
        <a href="#" class="img-wrap"> <img src="assets/images/items/0.jpg"> </a>

        <figcaption class="info-wrap">
          <a href="#" class="title">Analytik Jena - Element analyzer</a>
          <br>

          <button type="submit" class="btn btn-block btn-warning" href="/contact" routerLink = "/contact">Get an Offer </button>
        </figcaption>
      </div>
    </div> <!-- col.// -->

    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
      <div class="card card-sm card-product-grid">
        <a href="#" class="img-wrap"> <img src="assets/images/items/1.jpg"> </a>

        <figcaption class="info-wrap">
          <a href="#" class="title">Analytik Jena UV-VIS spectrophotometer</a>
          <button type="submit" class="btn btn-block btn-warning" href="/contact" routerLink = "/contact">Get an Offer </button>
        </figcaption>
      </div>
    </div> <!-- col.// -->

    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
      <div class="card card-sm card-product-grid">
        <a href="#" class="img-wrap"> <img src="assets/images/items/3.jpg"> </a>

        <figcaption class="info-wrap">
          <a href="#" class="title">Analytik Jena - Atomic Absorption spectrometer</a>
          <button type="submit" class="btn btn-block btn-warning" href="/contact" routerLink = "/contact">Get an Offer </button>
        </figcaption>

      </div>
    </div> <!-- col.// -->

    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
      <div class="card card-sm card-product-grid">
        <a href="#" class="img-wrap"> <img src="assets/images/items/2.jpg"> </a>

        <figcaption class="info-wrap">
          <a href="#" class="title">Analytik Jena - ICP spectrometer</a>
          <br>

          <button type="submit" class="btn btn-block btn-warning" href="/contact" routerLink = "/contact">Get an Offer </button>

        </figcaption>
      </div>
    </div> <!-- col.// -->

    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
      <div class="card card-sm card-product-grid">
        <a href="#" class="img-wrap"> <img src="assets/images/items/5.jpg"> </a>

        <figcaption class="info-wrap">
          <a href="#" class="title">Gas Analyzers</a>
          <br>
          <br>
          <button type="submit" class="btn btn-block btn-warning" href="/contact" routerLink = "/contact">Get an Offer </button>
        </figcaption>
      </div>
    </div> <!-- col.// -->

    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
      <div class="card card-sm card-product-grid">
        <a href="#" class="img-wrap"> <img src="assets/images/items/6.jpg"> </a>

        <figcaption class="info-wrap">
          <a href="#" class="title">Compound Sterilizers</a>
          <br>
          <br>
          <button type="submit" class="btn btn-block btn-warning" href="/contact" routerLink = "/contact">Get an Offer </button>
        </figcaption>
      </div>
    </div> <!-- col.// -->

    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
      <div class="card card-sm card-product-grid">
        <a href="#" class="img-wrap"> <img src="assets/images/items/12.jpg"> </a>
        <figcaption class="info-wrap">
          <a href="#" class="title">Centrafuges</a>
          <br>
          <br>
          <button type="submit" class="btn btn-block btn-warning" href="/contact" routerLink = "/contact">Get an Offer </button>
          
        </figcaption>
      </div>
    </div> <!-- col.// -->

    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
      <div class="card card-sm card-product-grid">
        <a href="#" class="img-wrap"> <img src="assets/images/items/7.jpg"> </a>

        <figcaption class="info-wrap">
          <a href="#" class="title">Laboratory Balances</a>
          <br>
          <br>
          <button type="submit" class="btn btn-block btn-warning" href="/contact" routerLink = "/contact">Get an Offer </button>
        </figcaption>
      </div>
    </div> <!-- col.// -->

    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
      <div class="card card-sm card-product-grid">
        <a href="#" class="img-wrap"> <img src="assets/images/items/8.jpg"> </a>
        <figcaption class="info-wrap">
          <a href="#" class="title">Reagents</a>
          <button type="submit" class="btn btn-block btn-warning" href="/contact" routerLink = "/contact">Get an Offer </button>
        </figcaption>
      </div>
    </div> <!-- col.// -->

    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
      <div class="card card-sm card-product-grid">
        <a href="#" class="img-wrap"> <img src="assets/images/items/9.jpg"> </a>

        <figcaption class="info-wrap">
          <a href="#" class="title">Ph Meters</a>
          <button type="submit" class="btn btn-block btn-warning" href="/contact" routerLink = "/contact">Get an Offer </button>
        </figcaption>
      </div>
    </div> <!-- col.// -->

    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
      <div class="card card-sm card-product-grid">
        <a href="#" class="img-wrap"> <img src="assets/images/items/11.jpg"> </a>

        <figcaption class="info-wrap">
          <a href="#" class="title">Distillators</a>
          <button type="submit" class="btn btn-block btn-warning" href="/contact" routerLink = "/contact">Get an Offer </button>
        </figcaption>
      </div>
    </div> <!-- col.// -->

    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
      <div class="card card-sm card-product-grid">
        <a href="#" class="img-wrap"> <img src="assets/images/items/10.jpg"> </a>

        <figcaption class="info-wrap">
          <a href="#" class="title">Germicidal Lamps</a>
          <button type="submit" class="btn btn-block btn-warning" href="/contact" routerLink = "/contact">Get an Offer </button>
        </figcaption>
      </div>
    </div> <!-- col.// -->

    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
      <div class="card card-sm card-product-grid">
        <a href="#" class="img-wrap"> <img src="assets/images/items/13.jpg"> </a>

        <figcaption class="info-wrap">
          <a href="#" class="title">Mouisture Analyzers</a>
          <button type="submit" class="btn btn-block btn-warning" href="/contact" routerLink = "/contact">Get an Offer </button>
        </figcaption>
      </div>
    </div> <!-- col.// -->

    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
      <div class="card card-sm card-product-grid">
        <a href="#" class="img-wrap"> <img src="assets/images/items/14.jpg"> </a>

        <figcaption class="info-wrap">
          <a href="#" class="title">CO2 Incubators</a>
          <button type="submit" class="btn btn-block btn-warning" href="/contact" routerLink = "/contact">Get an Offer </button>
        </figcaption>
      </div>
    </div> <!-- col.// -->

    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
      <div class="card card-sm card-product-grid">
        <a href="#" class="img-wrap"> <img src="assets/images/items/15.jpg"> </a>

        <figcaption class="info-wrap">
          <a href="#" class="title">Filtration Systems</a>
          <button type="submit" class="btn btn-block btn-warning" href="/contact" routerLink = "/contact">Get an Offer </button>
        </figcaption>
      </div>
    </div> <!-- col.// -->

    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
      <div class="card card-sm card-product-grid">
        <a href="#" class="img-wrap"> <img src="assets/images/items/16.jpg"> </a>

        <figcaption class="info-wrap">
          <a href="#" class="title">Monometers</a>
          <button type="submit" class="btn btn-block btn-warning" href="/contact" routerLink = "/contact">Get an Offer </button>
        </figcaption>
      </div>
    </div> <!-- col.// -->

    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
      <div class="card card-sm card-product-grid">
        <a href="#" class="img-wrap"> <img src="assets/images/items/17.jpg"> </a>

        <figcaption class="info-wrap">
          <a href="#" class="title">Thermo Shakers</a>
          <button type="submit" class="btn btn-block btn-warning" href="/contact" routerLink = "/contact">Get an Offer </button>
        </figcaption>
      </div>
    </div> <!-- col.// -->

    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
      <div class="card card-sm card-product-grid">
        <a href="#" class="img-wrap"> <img src="assets/images/items/18.jpg"> </a>

        <figcaption class="info-wrap">
          <a href="#" class="title">Muffle Furnace</a>
          <button type="submit" class="btn btn-block btn-warning" href="/contact" routerLink = "/contact">Get an Offer </button>
        </figcaption>
      </div>
    </div> <!-- col.// -->

    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
      <div class="card card-sm card-product-grid">
        <a href="#" class="img-wrap"> <img src="assets/images/items/19.jpg"> </a>

        <figcaption class="info-wrap">
          <a href="#" class="title">Laboratory Owens</a>
          <button type="submit" class="btn btn-block btn-warning" href="/contact" routerLink = "/contact">Get an Offer </button>
        </figcaption>
      </div>
    </div> <!-- col.// -->

    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
      <div class="card card-sm card-product-grid">
        <a href="#" class="img-wrap"> <img src="assets/images/items/20.jpg"> </a>

        <figcaption class="info-wrap">
          <a href="#" class="title">FTIR Spectrophotometers</a>
          <button type="submit" class="btn btn-block btn-warning" href="/contact" routerLink = "/contact">Get an Offer </button>
        </figcaption>
      </div>
    </div> <!-- col.// -->


  </div> <!-- row.// -->
</section>
</div>
