<section class="padding-bottom">
  <header class="section-heading heading-line">
    <h4 class="title-section text-uppercase">Request for Quotation</h4>
  </header>

  <div class="row">
    <div class="col-md-8">
      <app-banner-quote></app-banner-quote>
    </div> <!-- col // -->

    <div class="col-md-4">
      <app-quote-form></app-quote-form>
    </div> <!-- col // -->
  </div> <!-- row // -->
</section>
