<nav class="navbar navbar-main navbar-expand-lg navbar-light border-bottom">
  <div class="container">

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#main_nav"
      aria-controls="main_nav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="main_nav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" href="/" routerLink = "/">About Us</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/catalog" routerLink = "/catalog">Catalog of Products</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/services" routerLink = "/services">Technical Services</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/contact" routerLink = "/contact">Contact Us</a>
        </li>
      </ul>
      <ul class="navbar-nav ml-md-auto">
        <li class="nav-item dropdown" ngbDropdown>
          <a class="nav-link dropdown-toggle" href="#" ngbDropdownToggle data-toggle="dropdown">English</a>
          <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
            <a class="dropdown-item" href="#">Azerbaijani</a>
          </div>
        </li>
      </ul>
    </div> <!-- collapse .// -->

  </div> <!-- container .// -->
</nav>
