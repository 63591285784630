<form action="#" class="search-header">
  <div class="input-group w-100">
    <select class="custom-select border-right" name="category_name">
      <option value="">All type</option>
      <option value="codex">Special</option>
      <option value="comments">Only best</option>
      <option value="content">Latest</option>
    </select>
    <input type="text" class="form-control" placeholder="Search">

    <div class="input-group-append">
      <button class="btn btn-primary" type="submit">
        <fa-icon [icon]="faSearch"></fa-icon> Search
      </button>
    </div>
  </div>
</form> <!-- search-wrap .end// -->
