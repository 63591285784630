<div class="card-banner banner-quote overlay-gradient"
  style="background-image: url('assets/images/banners/banner9.jpg');">
  <div class="card-img-overlay white">
    <h3 class="card-title">An easy way to send request to suppliers</h3>
    <p class="card-text" style="max-width: 400px">
      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
      do eiusmod tempor incididunt.
    </p>
    <a href="" class="btn btn-primary rounded-pill">Learn more</a>
  </div>
</div>
