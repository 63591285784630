<ul class="row no-gutters bordered-cols">
  <li class="col-6 col-lg-3 col-md-4">
    <a href="#" class="item">
      <div class="card-body">
        <h6 class="title">Well made women clothes with trending collection </h6>
        <img class="img-sm float-right" src="assets/images/items/1.jpg">
        <p class="text-muted"><i class="fa fa-map-marker-alt"></i> Guanjou, China</p>
      </div>
    </a>
  </li>

  <li class="col-6 col-lg-3 col-md-4">
    <a href="#" class="item">
      <div class="card-body">
        <h6 class="title">Great clothes with trending collection </h6>
        <img class="img-sm float-right" src="assets/images/items/2.jpg">
        <p class="text-muted"><i class="fa fa-map-marker-alt"></i> Beijing, China</p>
      </div>
    </a>
  </li>

  <li class="col-6 col-lg-3 col-md-4">
    <a href="#" class="item">
      <div class="card-body">
        <h6 class="title">Demo clothes with sample collection </h6>
        <img class="img-sm float-right" src="assets/images/items/3.jpg">
        <p class="text-muted"><i class="fa fa-map-marker-alt"></i> Tokyo, Japan</p>
      </div>
    </a>
  </li>

  <li class="col-6 col-lg-3 col-md-4">
    <a href="#" class="item">
      <div class="card-body">
        <h6 class="title">Home and kitchen electronic stuff collection </h6>
        <img class="img-sm float-right" src="assets/images/items/4.jpg">
        <p class="text-muted"><i class="fa fa-map-marker-alt"></i> Tashkent, Uzb</p>
      </div>
    </a>
  </li>

  <li class="col-6 col-lg-3 col-md-4">
    <a href="#" class="item">
      <div class="card-body">
        <h6 class="title">Home and kitchen electronic stuff collection </h6>
        <img class="img-sm float-right" src="assets/images/items/5.jpg">
        <p class="text-muted"><i class="fa fa-map-marker-alt"></i> London, Britain</p>
      </div>
    </a>
  </li>

  <li class="col-6 col-lg-3 col-md-4">
    <a href="#" class="item">
      <div class="card-body">
        <h6 class="title">Home and kitchen electronic stuff collection </h6>
        <img class="img-sm float-right" src="assets/images/items/6.jpg">
        <p class="text-muted"><i class="fa fa-map-marker-alt"></i> Guanjou, China</p>
      </div>
    </a>
  </li>

  <li class="col-6 col-lg-3 col-md-4">
    <a href="#" class="item">
      <div class="card-body">
        <h6 class="title">Well made clothes with trending collection </h6>
        <img class="img-sm float-right" src="assets/images/items/7.jpg">
        <p class="text-muted"><i class="fa fa-map-marker-alt"></i> Hong Kong, China</p>

      </div>
    </a>
  </li>

  <li class="col-6 col-lg-3 col-md-4">
    <a href="#" class="item">
      <div class="card-body">
        <h6 class="title">Home and kitchen interior stuff collection </h6>
        <img class="img-sm float-right" src="assets/images/items/6.jpg">
        <p class="text-muted"><i class="fa fa-map-marker-alt"></i> Guanjou, China</p>
      </div>
    </a>
  </li>
</ul>
