<div style="background-color: white; height: 100vh; padding: 100px 200px;">
    <div class="jumbotron text-left" style="font-family: Arial">
        <p>
        Analitik LLC is a leading Sales and Service company in area Lab Equipment and Analytical Instruments for Life Science, Research, Industrial and Medical laboratories. 
        Our comprehensive range of products Autoclaves, Atomic Absorbtion Spectrometers, Optical Spectrophotometers, Optical and Electron Microscopes, Elemental Analyzers, Microwave Digestion Systems, ICP-MS, ICP-OES, Analyzers, Colony Counter, Cabinets, Flame Photometer, Fume Hood, Homogenizers, Hybridization Ovens, Laboratory Centrifuges, Laboratory Incubators, Labware, Laboratory Shakers & 3-D Rockers, Laboratory Furnaces, Laboratory Balances, Laboratory Ovens, Magnetic Stirrer & Hot Plates, Mixers & Vortexer, Microplate Readers & Washers, HPLC, Online Controller, pH Meters, Stomacher Blender, Sample Concentrators, , Sieve-Shakers,  Test Chambers, Test Pens, Ultrasonic Cleaners, Water & Oil Bath, 3D printers and it’s filaments, Telescopes, All Sky Cameras, Various sensors, Laboratory furniture, Thermometers, Multimeters, Oscilloscopes, Various electronic components, Digital dynamometers, Solar panel, Hybrid controllers (solar panel & wind turbine), Weather stations, Invertors, Supercondencator batteries  and etc. for the variety of applications.
        We provide premium quality Laboratory products and after sales support to our valued customers. For us, achieving a sale is only the beginning step of the process, and not the final step. Our priority is to complete the chain to the entire satisfaction of the user, starting with installation, commissioning, training, method development, technical service, periodic maintenance and instrument validations.
      </p>
      <br>
      <a href="#" class="img-wrap"> <img src="assets/images/banners/slide1.jpg" style="width: 480px; height: auto"> </a>
      </div>
</div>
