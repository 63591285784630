<div class="card card-body">
  <h4 class="title py-3">One Request, Multiple Quotes</h4>

  <form>
    <div class="form-group">
      <input class="form-control" name="" placeholder="What are you looking for?" type="text">
    </div>

    <div class="form-group">
      <div class="input-group">
        <input class="form-control" placeholder="Quantity" name="" type="text">

        <select class="custom-select form-control">
          <option>Pieces</option>
          <option>Litres</option>
          <option>Tons</option>
          <option>Gramms</option>
        </select>
      </div>
    </div>

    <div class="form-group text-muted">
      <p>Select template type:</p>
      <label class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" value="option1">
        <span class="form-check-label">Request price</span>
      </label>

      <label class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" value="option2">
        <span class="form-check-label">Request a sample</span>
      </label>
    </div>

    <div class="form-group">
      <button class="btn btn-warning">Request for quote</button>
    </div>
  </form>
</div>
