<div class="widgets-wrap float-md-right">
  <div class="widget-header mr-3">
    <a href="#" class="widget-view">
      <div class="icon-area">
        <fa-icon [icon]="faUser"></fa-icon>
        <span class="notify">3</span>
      </div>
      <small class="text"> My profile </small>
    </a>
  </div>
  <div class="widget-header mr-3">
    <a href="#" class="widget-view">
      <div class="icon-area">
        <fa-icon [icon]="faCommentDots"></fa-icon>
        <span class="notify">1</span>
      </div>
      <small class="text"> Message </small>
    </a>
  </div>
  <div class="widget-header mr-3">
    <a href="#" class="widget-view">
      <div class="icon-area">
        <fa-icon [icon]="faStore"></fa-icon>
      </div>
      <small class="text"> Orders </small>
    </a>
  </div>
  <div class="widget-header">
    <a href="#" class="widget-view">
      <div class="icon-area">
        <fa-icon [icon]="faShoppingCart"></fa-icon>
      </div>
      <small class="text"> Cart </small>
    </a>
  </div>
</div> <!-- widgets-wrap.// -->
