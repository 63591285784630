import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popular-category',
  templateUrl: './popular-category.component.html',
  styleUrls: ['./popular-category.component.less']
})
export class PopularCategoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
