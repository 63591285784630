<ngb-carousel>
  <ng-template ngbSlide>
    <img src="assets/images/banners/slide1.jpg" alt="First slide">
  </ng-template>

  <ng-template ngbSlide class="carousel-item">
    <img src="assets/images/banners/slide2.tiff" alt="Second slide">
  </ng-template>

  <ng-template ngbSlide class="carousel-item">
    <img src="assets/images/banners/slide3.tiff" alt="Third slide">
  </ng-template>
</ngb-carousel>
