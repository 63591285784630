<aside class="special-home-right">
  <h6 class="bg-blue text-center text-white mb-0 p-2">Popular category</h6>

  <div class="card-banner border-bottom">
    <div class="py-3" style="width:80%">
      <h6 class="card-title">Men clothing</h6>
      <a href="#" class="btn btn-secondary btn-sm"> Source now </a>
    </div>
    <img src="assets/images/items/1.jpg" height="80" class="img-bg">
  </div>

  <div class="card-banner border-bottom">
    <div class="py-3" style="width:80%">
      <h6 class="card-title">Winter clothing </h6>
      <a href="#" class="btn btn-secondary btn-sm"> Source now </a>
    </div>
    <img src="assets/images/items/2.jpg" height="80" class="img-bg">
  </div>

  <div class="card-banner border-bottom">
    <div class="py-3" style="width:80%">
      <h6 class="card-title">Home inventory</h6>
      <a href="#" class="btn btn-secondary btn-sm"> Source now </a>
    </div>
    <img src="assets/images/items/6.jpg" height="80" class="img-bg">
  </div>
</aside>
