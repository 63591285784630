<nav class="nav-home-aside">
  <h6 class="title-category"> Our Services <i class="d-md-none icon fa fa-chevron-down"></i></h6>

  <ul class="menu-category">
    <li><a href="#">Sales of high quality laboratory equipments</a></li>
    <li><a href="#">Sales of labaratory reagents, crystals and nanomaterials </a></li>
    <li><a href="#">Technical service and maintainance of laboratory equipments</a></li>
    <li><a href="#">Comprehensive training services to corporations on the operation and maintenance of laboratory equipment</a></li>
  </ul>

</nav>
