<footer class="section-footer bg-secondary">
  <div class="container">

    <section class="footer-top padding-y-lg text-white">
      <div class="row">
        <aside class="col-md col-6">
          <h6 class="title">Our Partners</h6>
          <ul class="list-unstyled">
            <li> <a href="https://www.analytik-jena.com">Analytik Jena</a></li>
          </ul>
        </aside>

        <aside class="col-md col-6">
          <h6 class="title">Help</h6>
          <ul class="list-unstyled">
            <li> <a href="#">Contact us</a></li>
          </ul>
        </aside>

        <aside class="col-md">
          <h6 class="title">Social</h6>
          <ul class="list-unstyled">
            <li><a href="#"> <fa-icon [icon]="faFacebook"></fa-icon> Facebook </a></li>
            <li><a href="#"> <fa-icon [icon]="faTwitter"></fa-icon> Twitter </a></li>
            <li><a href="#"> <fa-icon [icon]="faInstagram"></fa-icon> Instagram </a></li>
          </ul>
        </aside>
      </div> <!-- row.// -->
    </section> <!-- footer-top.// -->

    <section class="footer-bottom text-center row text-white">
      <div class="col-md-4">
        <p class="text-muted"> &copy; 2020 Analitik LLC, All rights reserved </p>
      </div>

      <div class="col-md-6 text-md-center">
        <span class="px-2">analitikbaku@gmail.com</span>
        <span class="px-2">+994 510 57 84</span>
        <span class="px-2">Baktiyar Vahabzada 20A, Baku, Azerbaijan</span>
      </div>
    </section>
  </div><!-- //container -->
</footer>
