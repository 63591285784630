<section class="section-name padding-y bg">
  <div class="container">

    <div class="row">
      <div class="col-md-6">
        <h3>Download app demo text</h3>
        <p>Get an amazing app to make Your life easy</p>
      </div>
      <div class="col-md-6 text-md-right">
        <a href="#"><img src="assets/images/misc/appstore.png" height="40"></a>
        <a href="#"><img src="assets/images/misc/appstore.png" height="40"></a>
      </div>
    </div> <!-- row.// -->
  </div><!-- container // -->
</section>
