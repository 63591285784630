<section class="padding-bottom">
  <header class="section-heading heading-line">
    <h4 class="title-section text-uppercase">Apparel</h4>
  </header>

  <div class="card card-home-category">
    <div class="row no-gutters">
      <div class="col-md-3">

        <app-home-category-banner></app-home-category-banner>
      </div> <!-- col.// -->

      <div class="col-md-9">
        <app-home-category-list></app-home-category-list>
      </div> <!-- col.// -->

    </div> <!-- row.// -->
  </div> <!-- card.// -->
</section>
